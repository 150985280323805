import AntdImage    from 'antd/lib/image';
import React        from 'react';
import Spin         from '@widesk-ui/components/Spin';
import useTheme     from '@widesk-ui/hooks/useTheme';
import { Skeleton } from 'antd';

export type ImageProps = {
	src?: string;
	height?: number;
	width?: number;
	style?: React.CSSProperties;
	fit?: 'contain' | 'cover' | 'fill'; // Default contain
	preview?: boolean;
	loading?: boolean;
	bordered?: boolean;
	size?: ImageSize;
	placeholder?: boolean; // when loading is over but src is empty we can display a placeholder or nothing
};

const sizes: Record<ImageSize, number> = {
	small: 20,
	middle: 40,
	large: 80,
	extralarge: 100,
};

const Image = (props: ImageProps) => {
	const { loading, src, size, bordered, placeholder } = props;
	const theme = useTheme();

	const fit = props.fit || 'contain';

	const width = size ? sizes[size] : props.width;
	const height = size ? sizes[size] : props.height;

	return (
		<Spin size="small" spinning={!!loading} style={{ lineHeight: 1 }}>
			{((typeof src === 'undefined') || (placeholder && !loading && !src)) ? (
				<Skeleton.Image style={{
					height: height ?? height,
					width: width ?? width,
					overflow: 'hidden',
				}} />
			) : (
				<AntdImage
					style={{
						borderRadius: 0,
						objectFit: fit,
						border: bordered ? `1px solid ${theme.colorBorder}` : undefined,
						...props.style,
					}}
					preview={props.preview && !!src}
					src={src}
					width={width}
					height={height}
				/>
			)}
		</Spin>
	);
};

export default Image;
