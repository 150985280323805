import ErrorPage                         from '@widesk-ui/pages/ErrorPage';
import CompleteApiModel                  from '@widesk-ui/models/CompleteApiModel';
import { createBreadcrumbRuleFromModel } from '@widesk-ui/components/BreadcrumbV2/tools';

function table(path: string, serviceName: string, listPageFolderName: string, title: string) {
	return [
		{
			handle: { crumb: () => 'Liste des ' + title.toLowerCase() },
			errorElement: <ErrorPage />,
			path: path,
			lazy: async () => {
				const Module = await import(`../../src/pages/services/${serviceName}/${listPageFolderName}/${listPageFolderName}Page.tsx`);
				return { Component: Module.default };
			},
		},
	];
}

function splitViewFromModel(
	Model: typeof CompleteApiModel,
	pageFolderName: string,
	options?: {
		path?: string;
		pathPrefix?: string;
		serviceName?: string;
		title?: string;
	},
) {
	createBreadcrumbRuleFromModel(Model, {
		noDashboard: true,
		regexPrefix: options?.pathPrefix?.replace(/:\w+/g, '\\d+'),
	});

	const data = {
		serviceName: Model.urnData.service,
		title: Model.localize('plural'),
		path: Model.path.replace(/\/\{\?id}$/, ''),
		pageFolderName: Model.name.replace(/Model[^Model]*$/, '') + 'SplitView',
		pathPrefix: '',

		...options,
	};

	return splitView(data.pathPrefix + data.path, data.serviceName, pageFolderName, data.title);
}

function dashboardFromModel(
	Model: typeof CompleteApiModel,
	pageFolderName: string,
	options?: {
		path?: string;
		pathPrefix?: string;
		serviceName?: string;
		title?: string;
	},
) {
	createBreadcrumbRuleFromModel(Model, {
		noList: true,
		regexPrefix: options?.pathPrefix?.replace(/:\w+/g, '\\d+'),
	});

	const data = {
		serviceName: Model.urnData.service,
		title: Model.localize(),
		path: Model.path.replace(/\/\{\?id}$/, ''),
		pageFolderName: Model.name.replace(/Model[^Model]*$/, '') + 'Dashboard',
		pathPrefix: '',

		...options,
	};

	return dashboard(data.pathPrefix + data.path, data.serviceName, pageFolderName, data.title);
}

function splitView(path: string, serviceName: string, pageFolderName: string, title: string) {
	return [
		{
			handle: { crumb: () => 'Liste des ' + title.toLowerCase() },
			errorElement: <ErrorPage />,
			path: path + '/split',
			lazy: async () => {
				const Module = await import(`../../src/pages/services/${serviceName}/${pageFolderName}/${pageFolderName}Page.tsx`);
				return { Component: Module.default };
			},
		},
	];
}

function dashboard(path: string, serviceName: string, pageFolderName: string, title: string) {
	return [
		{
			handle: { crumb: () => 'Fiche ' + title.toLowerCase() },
			errorElement: <ErrorPage />,
			path: path + '/:id',
			lazy: async () => {
				const Module = await import(`../../src/pages/services/${serviceName}/${pageFolderName}/${pageFolderName}Page.tsx`);
				return { Component: Module.default };
			},
		},
	];
}

export default { table, splitView, splitViewFromModel, dashboardFromModel, dashboard };
