import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('product_sales_status')
@doc.path('/product_sales_statuses/{?id}')
export default class ProductSalesStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_ProductSalesStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Shop_ProductSalesStatusReference;
}