import CompleteApiModel from '@widesk-ui/models/CompleteApiModel';
import React            from 'react';

const BreadcrumbModelName = React.memo(({ Model, id, link }: {
	Model: typeof CompleteApiModel;
	id: id;
	link?: boolean
}) => {
	const model = React.useMemo(() => new Model({ id }), [id]);

	React.useEffect(() => {
		model.fetch();
	}, [model.id]);

	return model.renderName({ link });
});

export default BreadcrumbModelName;