export interface Addable {
	addable: boolean;
}

export default function addable<T extends (abstract new (...args: any[]) => any)>(Base: T) {
	abstract class AddableClass extends Base implements Addable {
		public addable = true;
	}
	return AddableClass;
}
