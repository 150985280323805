import { BreadcrumbRule }             from './';
import CompleteApiModel               from '@widesk-ui/models/CompleteApiModel';
import BreadcrumbModelName            from './BreadcrumbModelName';
import { createGlobalBreadcrumbRule } from './globalRules';

type breadcrumbRuleFromModelOptions = {
	idPropertyName?: string | string[];
	noList?: boolean;
	noDashboard?: boolean;
	regexPrefix?: string;
};

export const breadcrumbRulesFromModel = (
	Model: typeof CompleteApiModel,
	options: breadcrumbRuleFromModelOptions = {
		idPropertyName: 'id',
		noList: false,
		noDashboard: false,
	},
): BreadcrumbRule[] => {
	const regexPrefixStr = `${options.regexPrefix || ''}/${Model.path.split('/').at(1)}/`;
	const regexList = new RegExp(regexPrefixStr);
	const regexDashboard = new RegExp(regexPrefixStr + `\\d+`);

	const rules: BreadcrumbRule[] = [];

	if (!options.noList) {
		rules.push({
			regex: regexList,
			exec: () => {
				return {
					title: Model.localize('plural'),
					href: Model.pagePath('splitView'),
					pageTitle: 'Liste des ' + Model.localize('plural').toLowerCase(),
				};
			},
		});
	}

	if (!options.noDashboard) {
		rules.push({
			regex: regexDashboard,
			exec: params => {
				const idName = Array.isArray(options.idPropertyName) ?
					(Object.keys(params).find(key => options.idPropertyName?.includes(key)) || 'id')
					: (options.idPropertyName || 'id');

				return {
					title: <BreadcrumbModelName Model={Model} id={params[idName]} link />,
					pageTitle: 'Fiche ' + Model.localize().toLowerCase(),
				};
			},
		});
	}

	return rules;
};

export function createBreadcrumbRuleFromModel(
	Model: typeof CompleteApiModel,
	options: breadcrumbRuleFromModelOptions = {
		idPropertyName: 'id',
		noList: false,
		noDashboard: false,
	},
) {
	const rules = breadcrumbRulesFromModel(Model, options);
	rules.forEach(createGlobalBreadcrumbRule);
}