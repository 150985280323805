import { BreadcrumbRule } from '.';

const globalRules: BreadcrumbRule[] = [];

export function createGlobalBreadcrumbRule(rule: BreadcrumbRule) {
	globalRules.push(rule);
}

export function getGlobalBreadcrumbRules() {
	return globalRules;
}