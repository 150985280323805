import doc              from '@widesk-core/decorators/doc';
import model            from '@widesk-core/decorators/model';
import ShopServiceModel from '@/models/ShopServiceModel';

@model.cacheDuration()
@model.urnResource('product_group_status')
@doc.path('/product_group_statuses/{?id}')
export default class ProductGroupStatusModel extends ShopServiceModel {
	private declare _tsFilters: {
		'reference': Shop_ProductGroupStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Shop_ProductGroupStatusReference;
}