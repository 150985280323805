export interface Editable {
	editable: boolean;
}

export default function editable<T extends (abstract new (...args: any[]) => any)>(Base: T) {
	abstract class EditableClass extends Base implements Editable {
		public editable = true;
	}
	return EditableClass;
}
