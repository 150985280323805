import { getLocale }  from '@widesk-core/locale';
import _get           from 'lodash/get';

export interface Translatable<Props extends Record<string, true>> {
	translation(name: keyof Props, lang: LanguageKey): any;
	fetchAllTranslations(): Promise<void>;
	get translations(): any;
	get properties(): Props;
	get propertyArr(): string[];
	get modelLabel(): string;
}

export default function translatable<Props extends Record<string, true>>(properties: Props) {
	return function<T extends (abstract new (...args: any[]) => any)>(Base: T) {
		abstract class TranslatableClass extends Base implements Translatable<Props> {
			public translation(name: keyof Props, lang: LanguageKey = getLocale()) {
				return this.get(`translations.${lang}.${name as string}`);
			}
	
			public get translations() {
				return this.get(`translations`);
			}
	
			public get properties() {
				return properties;
			}
	
			public get propertyArr() {
				return Object.keys(properties);
			}
	
			public async fetchAllTranslations() {
				if (this.id && Object.keys(this.translations || {}).length <= 1) {
					await this.fetch({ translations: '*' });
				}
			}
	
			// Dans l'ordre : cherche un label dans le model, puis les propriétés {properties} dans les translations,
			// ensuite les autres propriétés du model (ApiModel.modelLabel())
			public get modelLabel(): string {
				const priorityModelProperties = ['label'];
				const modelProperty = priorityModelProperties.find(v => _get(this, v, '') || _get(this.attributes, v, ''));
				if (modelProperty) {
					return _get(this, modelProperty, '') || _get(this.attributes, modelProperty, '');
				}
	
				const translationProperties = ['label', 'title', 'name'];
				if (this.translations && Object.keys(this.translations).length) {
					const translationProperty = translationProperties.find(p => this.translation(p));
					if (translationProperty) {
						return this.translation(translationProperty);
					}
				}
	
				return super.modelLabel;
			}
	
			constructor(...args: any[]) {
				super(...args);
			}
		}
		return TranslatableClass;
	};
}
