export interface Deletable {
	deletable: boolean;
}

export default function deletable<T extends (abstract new (...args: any[]) => any)>(Base: T) {
	abstract class DeletableClass extends Base implements Deletable {
		public deletable = true;
	}
	return DeletableClass;
}
