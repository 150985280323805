import { computed }   from 'mobx';
import dayjs          from 'dayjs';

export interface Timestampable {
	get createdAt(): dayjs.Dayjs;
	get updatedAt(): dayjs.Dayjs;
	get createdAtFormatted(): string;
	get updatedAtFormatted(): string;
}

export default function timestampable<T extends (abstract new (...args: any[]) => any)>(Base: T) {
	abstract class TimestampableClass extends Base implements Timestampable {
		public get createdAt() {
			return dayjs(this.get('createdAt', dayjs()));
		}

		public get updatedAt() {
			return dayjs(this.get('updatedAt', dayjs()));
		}

		public get createdAtFormatted(): string {
			return this.createdAt.format('L à LT');
		}

		public get updatedAtFormatted(): string {
			return this.updatedAt.format('L à LT');
		}

		constructor(...args: any[]) {
			super(...args);
		}
	}

	computed(TimestampableClass, 'createdAt');
	computed(TimestampableClass, 'updatedAt');

	return TimestampableClass;
}
