import appStore               from '@/stores/appStore';
import { ModelClass }         from '@mathquis/modelx/lib/types/collection';
import ApiModel               from '@widesk-core/models/ApiModel';
import ShopEntertainmentModel from '../services/shop/EntertainmentModel';
import ApiCollection          from '@widesk-core/models/ApiCollection';

export default function WithEntertainmentPath<T extends ModelClass<ApiModel>>(Base: T) {
	return class WithEntertainmentPath extends Base {

		private static _entertainmentPrefix(entertainmentId?: id) {
			return `/entertainments/${entertainmentId || (appStore.entertainment instanceof ShopEntertainmentModel ? appStore.entertainment.id : 'all')}`;
		}

		public get pagePath(): string {
			return `${WithEntertainmentPath._entertainmentPrefix(this.get('entertainment.id'))}${this.path}`;
		}

		public static pagePath(type?: 'list' | 'splitView') {
			switch (type) {
				case 'list':
					return `${this._entertainmentPrefix()}${(new ApiCollection(this as never)).path.slice(0, -1)}`;
				default:
					return `${this._entertainmentPrefix()}${(new ApiCollection(this as never)).path.slice(0, -1)}/split`;
			}
		}
	};
}
